import React, { useState } from "react";
import Flex from "app/components/Flex";
import styled from "styled-components";
import { VideoUploadEdge } from "services/graphql";
import { UserVideoUpload } from "app/components/UserVideos/UserVideoUpload";
import { UserVideoModal } from "app/components/UserVideos/UserVideoModal";

const Wrapper = styled(Flex)`
  gap: 16px;
`;

interface Props {
  videos: VideoUploadEdge[];
  fromModule: string;
}

export const UserPostList = ({ videos = [], fromModule }: Props) => {
  const [userVideoId, setUserVideoId] = useState(null);
  const openModal = ({ id, encodingStatus }: any) => {
    if (encodingStatus !== "available") {
      return;
    }

    setUserVideoId(id);
  };

  return (
    <>
      <Wrapper flexWrap="wrap" position="relative" width="100%">
        {videos.map(({ node }) => (
          <UserVideoUpload
            key={node.id}
            userVideo={node}
            openModal={openModal}
          />
        ))}
      </Wrapper>
      {!!userVideoId && (
        <UserVideoModal
          userVideoId={userVideoId}
          closeModal={() => setUserVideoId(null)}
          fromModule={fromModule}
        />
      )}
    </>
  );
};
